import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import styled from '@emotion/styled'

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const NotFoundContent = styled.div`
    text-align: center;
    p {
      font-size: 30px;
    }
  `

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <NotFoundContent>
        <h1>Not Found</h1>
        <p>
          Uhm...{' '}
          <span role="img" aria-label="thinking">
            🤔
          </span>{' '}
          this was unexpected.
        </p>
        <p> This route does not exist!</p>
      </NotFoundContent>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
